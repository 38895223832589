
    import { defineComponent } from 'vue';
    import { IonList, IonItem, IonLabel, IonRadioGroup, IonRadio, IonListHeader } from '@ionic/vue';
    import { store } from '../../store/store';
    import { chevronForwardSharp } from 'ionicons/icons';
    import { LanguageMixin } from '@/mixins/LanguageMixin';

    export default defineComponent({
        name: 'Language',
        mixins: [LanguageMixin],
        components: {
            IonList, IonItem, IonLabel, IonRadioGroup, IonRadio, IonListHeader
        },
        data() {
            return {
                lang: store.state.currentLanguage
            }
        },
        methods: {
            langChange(ev: any) {

                LanguageMixin.methods.changeLanguage(ev.detail.value);
                window.location.reload();
            }
        },
        setup() {
            let loggedInUser = {};

            loggedInUser = store.state.loggedInUser

            return {
                chevronForwardSharp,
                loggedInUser
            }
        }
    })
